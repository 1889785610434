import React, { Component } from "react"
import { bgService } from "../../../images"
import { BackgroundImages } from "../../../shared"
import BoxItemServices from "./BoxItemServices"
import { OutboundLink } from 'gatsby-plugin-gtag'

class Services extends Component {
  constructor(props) {
    super(props);
    this.renderHeader = this.renderHeader.bind(this);
  }

  renderHeader() {
    return (
      <div className="header-services">
        <BackgroundImages style={{
          backgroundImage: `url(${bgService})`,
        }}>
          <OutboundLink href="/contact-us"className="text-services">Contact us</OutboundLink>
        </BackgroundImages>
      </div>
    )
  }

  render() {
    return (
      <div className="wrapper-services">
        {this.renderHeader()}
        <div className="middle-services">
          <h1 className="title-services">
            Our Services
          </h1>
          <p className="content-services">
            Open Techiz is a dedicated e-commerce agency, specialized in web development with Magento and other open sources.<br/>
            We cover spectrum of Web Development Service. Completely e-commerce & CMS web site production with full control on quality standards. We also have extensive experience working with big catalog size with 100K+ items.
          </p>
          <BoxItemServices
            image={require("../../../images/jpg/services-1.jpg")}
            contentSub="All of our Web designs are optimized for a fluid User Experience from landing page to checkout"
            subServices="Web design"
            link="/services/web-design"
          />
          <BoxItemServices
            image={require("../../../images/jpg/services-2.jpg")}
            contentSub="We partner with many Agencies and end-user Clients to create beautiful and conversion driven e-Commerce websites"
            subServices="WEB DEVELOPMENT"
            link="/services/web-development"
          />
          <BoxItemServices
            image={require("../../../images/jpg/services-3.jpg")}
            contentSub="We offer several different retainer options for clients who need ongoing ecommerce support."
            subServices="DEDICATED SUPPORT"
            link="/services/dedicated-support"
          />
          <BoxItemServices
            image={require("../../../images/jpg/services-4.jpg")}
            contentSub="We located in Vietnam - the outsourcing destination of choice"
            subServices="OUTSOURCING"
            link="/services/outsourcing"
          />
        </div>
      </div>
    )
  }
}

export default Services;
