import React, { Component } from "react"
import {
  Image
} from "react-bootstrap"
import { OutboundLink } from 'gatsby-plugin-gtag'

class BoxItemServices extends Component {
  render() {
    return (
      <div className="box-item-services">
        <Image
          alt="900x500"
          className="image-box-item-services lazyload"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
          data-src={this.props.image}
        />
        <div className="link-sub-services">
          <OutboundLink href={this.props.link} className="title-sub-services">
            {this.props.subServices}
          </OutboundLink>
          <p className="content-sub-services">
            {this.props.contentSub}
          </p>
          <OutboundLink href={this.props.link} className="view-more">
            View more
          </OutboundLink>
        </div>
      </div>
    )
  }
}

export default BoxItemServices;
