import React from "react"
import Layout from "../components/layout"
import Services from "../components/screens/Services"
import { Helmet } from "react-helmet"

const ServicesPage = () => (
  <Layout pageInfo={{ pageName: "Services" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Services</title>
        <meta name="description" content="We provide solution, development service, testing service, DevOps service and maintenance service" />
    </Helmet>
    <Services />
  </Layout>
)

export default ServicesPage
